@import "./Window.scss";

#experience {
  top: 110px;
  left: 50px;

  .title-panel {
    min-width: 500px;
  }

  p {
    margin-top: .5rem;
  }
}
