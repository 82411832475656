#app-header {
  padding-bottom: 0.4rem;

  #app-header-content {
    width: 98vw;
    margin-left: 1vw;

    display: flex;
    align-items: center;

    button {
      img {
        max-width: 25px;
        margin-right: 0.25rem;
      }
    }

    h1 {
      margin-left: 1rem;
    }
  }
}
