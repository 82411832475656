@import "../StartMenu.scss";

.panel {
  z-index: $start-menu-zindex;
  min-height: 44px;

  li {
    justify-content: flex-start;
    cursor: pointer !important;

    img {
      max-height: 60%;
    }

    span {
      margin-left: 0.5rem;
    }
  }

  &.hidden {
    display: none;
  }
}
