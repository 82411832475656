@import "./Window.scss";

#intro {
    top: 50px;
    width: 50vw;

    .separator {
        height: 10px;
    }
}
